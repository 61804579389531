import {
	PartialNormalizedResource,
	ResourceType,
	CreditLiability,
	CreditProvider,
	Periodicity,
	ProductDurationConfig,
} from '@oper-client/shared/data-model';

import { InputField, InputSelect, PercentageInputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { convertCreditProviderToOptions } from '@oper-client/shared/util-data-model-transform';
import { DATE_RESTRICTION } from '../../services/validator-constants';
import { of } from 'rxjs';

export default function (
	formData?: Partial<CreditLiability>,
	resources?: PartialNormalizedResource,
	creditProviders?: CreditProvider[],
	durationConfig?: Partial<ProductDurationConfig & { periodicity?: Periodicity }>,
	isMonthlyAmortizationCalculated?: boolean
): InputBase<any>[] {
	const minStartDate: number = ValidatorService.getDateByYearOffset(50).getTime();
	const currentDate: number = ValidatorService.getDateByYearOffset(0).getTime();
	return [
		new InputSelect({
			key: 'creditProvider.id',
			label: 'ç.question.lender.label',
			value: formData?.creditProvider?.id,
			required: false,
			options: creditProviders ? convertCreditProviderToOptions(creditProviders) : [],
			class: 'span4',
		}),
		new InputField({
			key: 'contractNumber',
			label: 'ç.question.contractNumber.label',
			type: 'text',
			value: formData?.contractNumber,
			required: false,
			class: 'span4',
		}),
		new InputField({
			key: 'initialAmount',
			label: 'ç.question.initialAmount.label',
			value: formData?.initialAmount,
			required: true,
			type: 'number',
			currency: true,
			class: 'span4',
		}),
		new PercentageInputField({
			key: 'interestRate',
			label: 'ç.question.interestRate.label',
			value: Math.round((formData?.interestRate * 100 + Number.EPSILON) * 100) / 100,
			required: false,
			type: 'number',
			class: 'span4',
		}),
		new InputField({
			key: 'startDate',
			label: 'ç.question.startDate.label',
			value: formData?.startDate,
			required: false,
			type: 'date',
			class: 'span4',
			validators: [
				ValidatorService.getDateRestrictionValidatior('past', currentDate, DATE_RESTRICTION.FUTURE),
				ValidatorService.getDateRestrictionValidatior('notEarlierThanYear', minStartDate, DATE_RESTRICTION.PAST),
			],
			min: minStartDate,
			max: currentDate,
		}),
		new InputField({
			key: 'endDate',
			label: 'ç.question.endDate.label',
			value: formData?.endDate,
			required: false,
			type: 'date',
			class: 'span4',
			validators: [ValidatorService.getDateRestrictionValidatior('future', currentDate, DATE_RESTRICTION.PAST)],
			min: currentDate,
		}),
		new InputField({
			key: 'balance',
			label: 'ç.question.balance.label',
			value: formData?.balance,
			required: false,
			type: 'number',
			currency: true,
			class: 'span6',
		}),
		...(durationConfig.periodicity === Periodicity.YEARLY
			? [
					new InputField({
						key: 'amount',
						label: 'ç.question.amortizationYearly.label',
						value: formData?.amount,
						required: true,
						type: 'number',
						currency: true,
						class: 'span6',
					}),
				]
			: [
					new InputField({
						key: 'monthlyAmount',
						label: 'ç.question.amortizationMonthly.label',
						value: formData?.monthlyAmount,
						required: true,
						type: 'number',
						currency: true,
						class: 'span6',
						disabled: isMonthlyAmortizationCalculated,
						helpText: isMonthlyAmortizationCalculated ? of('ç.feature.client.liability.monthlyAmount.helpText') : null,
					}),
				]),
		new InputSelect({
			key: 'regularity.id',
			label: 'ç.question.regularity.label',
			value: formData?.regularity?.id,
			required: false,
			options: [{ id: '', definition: '' }, ...resources[ResourceType.REGULARITY_TYPE]],
			class: 'span4',
		}),
		new InputSelect({
			key: 'takeover.id',
			label: 'ç.question.takeover.label',
			value: formData?.takeover?.id,
			required: true,
			options: resources[ResourceType.CREDIT_TAKEOVER],
			class: 'span4',
		}),
		new InputField({
			key: 'earlyRepaymentFee',
			label: 'ç.question.earlyRepaymentFee.label',
			value: formData?.earlyRepaymentFee,
			required: false,
			type: 'number',
			currency: true,
			class: 'span4',
		}),
	];
}
