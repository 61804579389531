import { Resource } from './resource.model';
import { LoanRequest } from './loan-request.model';
import { PhoneNumber } from './phone.model';
import { Address } from './address.model';
import { Email } from './email.model';
import { CreditProvider } from './offer.model';
import { Income } from './income.model';

export interface LoanRequestClientSlim {
	readonly id: number;
	readonly loanRequest: LoanRequest;
	client: Client;
	role: Resource;
}

export interface Relationship {
	id?: number;
	clientFrom: Partial<Client>;
	clientTo: Partial<Client>;
	relationship: Resource;
	liveInTheSameHousehold: boolean;
}

export enum ClientRoleEnum {
	BORROWER = 'borrower',
	CHILD = 'child',
}

export interface Client {
	id: number;
	civilStatus?: Resource;
	sex?: Resource;
	title?: Resource;
	verified?: boolean;
	emails?: Array<Email>;
	phoneNumbers?: Array<PhoneNumber>;
	addresses?: Array<Address>;
	firstName: string;
	middleName?: string;
	lastName: string;
	maidenName?: string;
	motherMaidenName?: string;
	nationality?: Resource;
	birthDate?: string;
	birthCity?: string;
	birthCountry?: Resource;
	nationalNumber?: string;
	idIssuanceDate?: string;
	idExpirationDate?: string;
	idCardNumber?: string;
	idDocumentType?: Resource;
	complianceTerror?: boolean;
	complianceBank?: boolean;
	complianceFraud?: boolean;
	language?: Resource;
	relationships?: Array<Relationship>;
	lastNbbConsulation?: Date;
	employmentStatus?: Resource;
	loanRequest: Partial<LoanRequest>;
	role: Resource;
	livingStatus?: Resource;
	education?: Resource;
	futureLivingStatus?: Resource;
	countryArrivalDate?: string;
	dependentChildren?: number;
	plannedChildren?: number;
	creditProvider?: CreditProvider;
	bic?: string;
	iban?: string;
	localBankAccount?: string;
	compliancePersonalizedInformation?: Resource;
	isFirstTimeBuyer: boolean;
	externalReference?: string;
	taxId: string;
	addressCardId?: string;
	hasPoliticallyExposedPersonStatus?: boolean;
	socialInsuranceStartDate?: Resource;
	incomes?: Income[];

	// KYC Onboarding
	lenderOnboardingStatus?: LenderOnboardingStatus;
	onboardingRedirectionUrl?: string;

	// CH-specific
	residencePermit?: Resource;
	isTaxResidenceInSwiss?: boolean;
	isTaxResidenceInUsa?: boolean;
	isDebtCollection?: boolean;
	numberOfIncomeEarnersInTheHousehold?: number;
	numberOfFetusesGteTwelveWeeks?: number;
	numberOfDependents?: number;
	expectedFetusesBirthDate?: string;
	legalStatusType?: Resource;
	isClaimingTaxRefund?: boolean;
	is36MonthMoratoriumOnRepaymentsRequired?: boolean;

  //Creafin specific
  aCardDetails?: Resource;
}

export enum InviteType {
	PRE_APPROVAL = 'preApproval',
	DOCUMENT_MANAGEMENT = 'documentManagement',
}

export enum AddressType {
	'temporary' = 'temporary',
	'correspondence' = 'correspondence',
}

interface LenderOnboardingStatus {
	id: number;
	order: number;
	definition: 'notRequired' | 'pending' | 'inProgress' | 'completed';
}

export const AddressTypesConfig = {
	main: 1,
	correspondence: 2,
	temporary: 3,
};
