import { Resource } from './resource.model';
import { User } from './user.model';
import { Address } from './address.model';
import { DocumentLibrary } from './document-library.model';
import { ProductItem } from './offer.model';

//TODO This is called ProofObject on the backend
export interface Document {
	id: number;
	documentType: Resource;
	status: Resource;
	mimeType: string;
	url?: string;
	generatedDocument?: Document;
	unsignedDocument?: Document;
	informativeDocument?: Document;
	validFrom?: string;
	version?: number;
	audience?: Resource;
	context?: string;
	created: Date;
	fileName?: string;
	rejectionReasonType?: Resource;
	rejectionReason?: string;
	assessmentBy?: User;
	assessmentDate?: string;
	toBeSignedDocument?: boolean;
	isInformativeDocument?: boolean;
	externalId?: string;
	statusHistory?: Array<{
		created: Date;
		fromStatus: Resource;
		toStatus: Resource;
		rejectionReason: string;
		rejectionReasonType: Resource;
		modifiedBy: {
			id: number;
			firstName: string;
			lastName: string;
			role: Resource;
		};
	}>;
}

export enum DocumentTypes {
	toBeSigned = 'documentSigningRequired',
	informative = 'isInformativeDocument',
	consultation = 'consultation',
}

export enum DocumentDefinitions {
	MFO = 'certifiedConsumerFriendlyHomeInsurance',
}

export type DocumentAudiences = 'client' | 'analyst';

// TODO: add all meta data when back end is finished
export interface Meta {
	// Client
	firstName?: string;
	lastName?: string;

	// Realty
	address?: Address;

	// LoanRequest
	internalReference?: string;

	// Liability
	liabilityType?: Resource;
	periodicity?: Resource;
	startDate?: Date;
	amount?: number;
	provider?: any;
	client?: any;

	// Insurances
	realty?: any;
	insuredAmount?: number;
	premium?: number;
	policyNumber?: number;
}

export interface ProofEntity {
	category: Resource;
	id: number;
	meta: any;
}

export interface Proof {
	id: number;
	entity: ProofEntity;
	documents: Array<Document>;
}

export interface ProofSelectionState {
	selectable: boolean;
	selected: boolean;
	documents: (Partial<Document> & { selectable: boolean; selected: boolean })[];
}

// Endregion Actual models

export enum DocumentStatus {
	VERIFIED = 'verified',
	REJECTED = 'rejected',
	INFORMATIVE = 'informative',
	TO_BE_SIGNED = 'toBeSigned',
	TO_BE_VERIFIED = 'toBeVerified',
	REQUIRED = 'required',
	INVALID = 'invalid',
	REQUESTED = 'requested',
	VERIFIED_BY_ADVISOR = 'verifiedByAdvisor',
	VERIFIED_BY_ANALYST = 'verifiedByAnalyst',
	REJECTED_BY_ADVISOR = 'rejectedByAdvisor',
	REJECTED_BY_ANALYST = 'rejectedByAnalyst',
	SENT_TO_LENDER = 'sentToLender',
	DOUBLE_VERIFIED = 'doubleVerified',
	SIGNED = 'signed',
}

export interface DocumentLibraryUrlParams {
	creditProvider?: number;
	documentType?: 'manualAcceptanceRules';
	language: string;
	active?: boolean;
	creditProviderActiveExclude?: boolean;
}

export type GenerateDocumentState = 'loading' | 'pending' | 'success' | 'failed';

export interface GenerateDocumentOptions {
	language?: Resource | null;
	documentType?: string;
	libraryDocument?: Partial<DocumentLibrary>;
	loanOfferIds?: number[];
	productItem?: Partial<ProductItem>;
	currency?: string;
}

export interface BorrowerUploadDocumentType {
	id: number;
	definition: string;
	order: number;
	addToHandoverLetter: boolean;
	appendFileVersion: boolean;
	isInformative: boolean;
	isProofToSign: boolean;
	selected?: boolean;
}
