import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { FinancialPlanItem, PartialNormalizedResource, Simulator } from '@oper-client/shared/data-model';
import { ValidatorFn, Validators } from '@angular/forms';

export default function (
	formData?: Partial<Simulator.Simulation>,
	resources?: PartialNormalizedResource,
	isReadonly = false
): InputBase<any>[] {
	const items: FinancialPlanItem[] = [];

	formData?.financialPlanOwnFundItems
		?.filter((item) => item.financialPlanOwnFundField.isOverridable && item.financialPlanOwnFundField.isMain)
		.map((item) => ({ ...item, field: { ...item.financialPlanOwnFundField, type: item.financialPlanOwnFundField.ownFundType } }))
		.forEach((item) => items.push(item));

	formData?.financialPlanLoanAmountItems
		?.filter((item) => item.financialPlanLoanAmountField.isOverridable && item.financialPlanLoanAmountField.isMain)
		.map((item) => ({
			...item,
			field: { ...item.financialPlanLoanAmountField, type: item.financialPlanLoanAmountField.loanAmountType },
		}))
		.forEach((item) => items.push(item));

	const validators: ValidatorFn[] = [Validators.required, Validators.min(0)];
	if (formData.totalCosts) {
		validators.push(Validators.max(formData.totalCosts));
	}

	return items.map((item) => getFinancialPlanInputField(item, isReadonly, validators)).sort((a, b) => a.order - b.order);
}

function getFinancialPlanInputField(item: FinancialPlanItem, isReadonly = false, validators?: ValidatorFn[]): InputField {
	const defaultSettings: Partial<InputField> = {
		key: item.field.type.definition,
		label: `ç.feature.mortgageSimulator.report.overview.${item.field.type.definition}.label`,
		value: item.amount ? +item.amount.toFixed(2) : 0,
		type: 'number',
		validators: [...(validators ?? [])],
		disabled: isReadonly,
		currency: true,
		required: false,
		order: item.field.isMain && item.field.isOverridable ? 1 : item.field.type.order,
	};

	return new InputField({
		...defaultSettings,
	});
}
