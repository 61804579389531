import { FormControl, FormGroup } from '@angular/forms';
import { FormConfiguration } from './dynamic-form.model';
import { InputBase, LabelDefinition } from './input-base.model';
import { Observable, of } from 'rxjs';
import { deepClone, getValueByKeyPath } from '@oper-client/shared/util-object';
import { AnyObject, UiColor } from '@oper-client/shared/data-model';

export type HtmlInputType =
	| 'button'
	| 'checkbox'
	| 'color'
	| 'date'
	| 'datetime-local'
	| 'email'
	| 'file'
	| 'hidden'
	| 'image'
	| 'month'
	| 'number'
	| 'password'
	| 'radio'
	| 'range'
	| 'reset'
	| 'search'
	| 'submit'
	| 'tel'
	| 'text'
	| 'time'
	| 'url'
	| 'week'
	| 'select'
	| 'table'
	| 'percentage';

/**
 * Represents the settings for an endpoint used for dynamic data fetching feeding the dynamic-async-search-input-field-component.
 *
 * @interface EndpointSettings
 * @property {function(params?: any): Observable<any>} method The primary method to fetch data from the endpoint. Return an observable made of an injection and a service call
 * @property {function(data: any): any} [transform] An optional transformation function to apply to the data received from the `method`.
 * @property {function(params?: any): Observable<any>} [initialLoadMethod] An optional method to fetch initial data when the component loads. This is useful for pre-populating dropdowns or other controls with default values.
 * @property {function(value: any): any} [transformSelectedValue] This method is used to apply a transform to the selected value (eg: To avoid chaining API calls if we need an extra call to get the data we need, instead of doing the call on each displayed value, we can do it only on the selected one on selection)
 */
export interface EndpointSettings {
	method: (params?: any) => Observable<any>;
	transform?: (data: any) => any;
	initialLoadMethod?: (params?: any) => Observable<any>;
	transformSelectedValue?: (value: any) => any;
}

export class InputField extends InputBase<any> {
	type: HtmlInputType;
	currency: boolean;
	min?: any;
	max?: any;
	maxlength?: number;
	multiline: boolean;
	transform?: (x: any) => any;
	onlyNumbers: boolean;
	digitsInfo: string;
	readonly: boolean;
	placeholder?: string;
	badgeLabel?: string;
	badgeColor?: UiColor;
	onlyLetters: boolean;
	revealPassword: boolean;
	showPasswordStrength: boolean;
	immediatePasswordCheck: boolean;
	configureOnHidden: boolean;

	constructor(args: Partial<InputField>) {
		super(args);
		this.controlType = 'field';
		this.type = args.type;
		this.currency = args.currency ?? false;
		this.min = args.min;
		this.max = args.max;
		this.maxlength = args.maxlength;
		this.multiline = args.multiline ?? false;
		this.transform = args.transform;
		this.onlyNumbers = args.onlyNumbers ?? false;
		this.digitsInfo = args.digitsInfo;
		this.readonly = args.readonly ?? false;
		this.placeholder = args.placeholder;
		this.onlyLetters = args.onlyLetters ?? false;
		this.revealPassword = args.revealPassword ?? true;
		this.showPasswordStrength = args.showPasswordStrength ?? false;
		this.immediatePasswordCheck = args.immediatePasswordCheck ?? false;
		this.configureOnHidden = args.configureOnHidden ?? false;
	}
}

export class InputSelect extends InputBase<any> {
	options: any[]; // TODO add a more specific type hint (SelectOption[] ?)
	bindValue: string;
	defaultLabel: string;
	alreadySorted: boolean;
	multiple: boolean;
	searchable: boolean;
	prefillDefaultValue: boolean;
	locked: boolean;
	type: 'select' | 'hidden';
	clearable: boolean;
	searchFn: (term: string, item: any) => boolean;
	beta: boolean;
	bindLabel: string;

	constructor(args: Partial<InputSelect>) {
		super(args);
		this.controlType = 'select';
		this.type = 'select';
		this.options = args.options ?? [];
		this.defaultLabel = args.defaultLabel || 'ç.question.defaultLabel';
		this.alreadySorted = args.alreadySorted ?? false;
		this.multiple = args.multiple ?? false;
		this.searchable = args.searchable ?? true;
		this.clearable = args.clearable ?? this.required === false;
		this.searchFn = args.searchFn;
		this.prefillDefaultValue = args.prefillDefaultValue ?? false;
		this.locked = args.locked;
		this.bindValue = args.bindValue ?? 'id';
		this.beta = args.beta;
		this.bindLabel = args.bindLabel;
		this.hideRequiredAsterisk = args.hideRequiredAsterisk ?? false;
	}
}

/**
 * Represents an asynchronous search input field. This input allows users to search for options
 * by querying an endpoint as they type.  The search is debounced and has a character
 * threshold to avoid excessive requests.
 * Important propriety is `endpointSettings`. See {@EndpointSettings} for more details.
 * @extends InputBase<any>
 *
 * @class InputAsyncSearch
 * @extends {InputBase<any>}
 */
export class InputAsyncSearch extends InputBase<any> {
	type: 'select' | 'hidden'; // The type of underlying HTML input element. Can be 'select' or 'hidden'.
	placeholder?: string; // Placeholder text to display in the input field used for search.
	onlyLetters: boolean; // Whether to allow only letters in the input.
	required: boolean; // Whether the input is required.
	debounceTime?: number; // The debounce time in milliseconds to delay search requests.
	characterThreshold?: number; // The minimum number of characters required before triggering a search.
	iconName?: string; // The name of the icon to display within the input field (optional).
	hideArrow?: boolean; // Whether to hide the dropdown arrow.
	endpointSettings: EndpointSettings; // The settings for the endpoint to query for search results. Consider making this required.
	bindValue: string; // The property name to use for binding the value of the selected option.
	bindLabel: string; // The property name to use for binding the label of the selected option.
	prefillDefaultValue?: boolean; // Whether to prefill the input with the default value if available.
	noFoundTextLabel?: string; // label to show when no results are found
	clearAfterSearch?: boolean; // optional clearing of the field after the search

	constructor(args: Partial<InputAsyncSearch>) {
		super(args);
		this.controlType = 'asyncSearch';
		this.type = 'select';
		this.transform = args.transform;
		this.placeholder = args.placeholder;
		this.required = args.required ?? false;
		this.debounceTime = args.debounceTime ?? 0;
		this.characterThreshold = args.characterThreshold ?? 1;
		this.endpointSettings = args.endpointSettings || null;
		this.iconName = args.iconName || null;
		this.onlyLetters = args.onlyLetters || false;
		this.hideArrow = args.hideArrow || false;
		this.bindValue = args.bindValue ?? 'id';
		this.bindLabel = args.bindLabel ?? 'name';
		this.prefillDefaultValue = args.prefillDefaultValue || false;
		this.noFoundTextLabel = args.noFoundTextLabel;
		this.clearAfterSearch = args.clearAfterSearch || false;
	}
}

export class InputRadio extends InputBase<any> {
	options: any[]; // TODO add a more specific type hint (SelectOption[] ?)
	constructor(args: Partial<InputSelect>) {
		super(args);
		this.controlType = 'radio';
		this.options = args.options ?? [];
	}
}

export class InputSwitch extends InputBase<any> {
	type: 'button' | 'toggle';
	labelA: string;
	labelB: string;
	valueA: any;
	valueB: any;

	constructor(args: Partial<InputSwitch>) {
		super(args);
		this.controlType = 'switch';
		this.type = args.type;
		this.labelA = args.labelA;
		this.labelB = args.labelB;
		this.valueA = args.valueA;
		this.valueB = args.valueB;
	}
}

export class InputPhone extends InputBase<any> {
	onlyCountries: any[];
	allowedCountries: any[];
	allowedTypes: any[];
	validatePhoneType: boolean;
	enablePlaceholder: boolean;

	constructor(args: Partial<InputPhone>) {
		super(args);
		this.controlType = 'phone';
		this.onlyCountries = args.onlyCountries || [];
		this.allowedCountries = args.allowedCountries || [];
		this.allowedTypes = args?.allowedTypes?.length > 0 ? args.allowedTypes : ['FIXED_LINE_OR_MOBILE', 'FIXED_LINE', 'MOBILE'];
		this.validatePhoneType = args.validatePhoneType || true;
		this.enablePlaceholder = args.enablePlaceholder ?? true;
	}
}

export class Space extends InputBase<any> {
	constructor(args: Partial<InputField>) {
		super(args);
	}
}

export class Section extends InputBase<any> {
	title: string;
	titleTranslateParams: AnyObject<any> | null;

	constructor(args: Partial<Section>) {
		super(args);
		this.controlType = 'section';
		this.title = args.title || '';
		this.titleTranslateParams = args.titleTranslateParams ?? null;
	}
}

export class NamedSection extends InputBase<any> {
	user: Partial<{ firstName: string; lastName: string }>;
	iconName: string;
	iconBackground: boolean;

	constructor(args: Partial<NamedSection>) {
		super(args);
		this.controlType = 'named-section';
		this.user = args.user || { firstName: '', lastName: '' };
		this.iconName = args.iconName || undefined;
		this.iconBackground = args.iconBackground || false;
	}
}

export class HeaderSection extends InputBase<any> {
	title: string;
	subtitle: string;

	constructor(args: Partial<HeaderSection>) {
		super(args);
		this.controlType = 'header';
		this.title = args.title;
		this.subtitle = args.subtitle;
	}
}

export class InformationBox extends InputBase<any> {
	title: string;
	content: string;
	color: 'default' | 'information' | 'warning' | 'error';
	icon: string;

	constructor(args: Partial<InformationBox>) {
		super(args);
		this.controlType = 'information-box';
		this.title = args.title;
		this.content = args.content;
		this.color = args.color ?? 'default';
		this.icon = args.icon ?? 'faCircleExclamation';
	}
}

export class DynamicFormCard extends InputBase<any> {
	title: string;
	subtitle: string;
	formConfiguration: FormConfiguration;
	requiredOnOpen?: boolean;
	debounceTime: number;
	showDeleteButton: boolean;
	extractSingleNestedFormControl?: boolean;
	clearOnClose?: boolean;
	forceExpand?: boolean;
	type: 'card' | 'hidden';

	constructor(args: Partial<DynamicFormCard>) {
		super(args);
		this.controlType = 'card';
		this.type = args.type ?? 'card';
		this.title = args.title;
		this.subtitle = args.subtitle;
		this.forceExpand = args.forceExpand;
		this.formConfiguration = args.formConfiguration;
		this.debounceTime = args.debounceTime;
		this.showDeleteButton = args.showDeleteButton;
		this.requiredOnOpen = args.requiredOnOpen ?? false;
		this.clearOnClose = args.clearOnClose ?? true;
		this.extractSingleNestedFormControl = args.extractSingleNestedFormControl ?? false;
	}
}

export class DynamicInputTable extends InputBase<any> {
	private readonly questions: InputBase<any>[];
	rows: FormConfiguration[];
	headerDefinitions: LabelDefinition[];
	columns: InputBase<any>[];
	debounceTime: number;
	type: 'table' | 'hidden';
	addRowLabel: string;
	value: Array<any>;
	showAsCard: boolean;
	cardTitle: string;
	cardSubtitle: string;
	showDeleteButton: boolean;
	isSingleRow: boolean;

	constructor(args: Partial<DynamicInputTable>) {
		super(args);
		this.controlType = 'table';
		this.type = 'table';
		this.showAsCard = args.showAsCard ?? false;
		this.cardTitle = args.cardTitle;
		this.cardSubtitle = args.cardSubtitle;
		this.showDeleteButton = args.showDeleteButton ?? false;
		this.isSingleRow = args.isSingleRow ?? false;
		this.columns = args.columns ?? [];
		this.addRowLabel = args.addRowLabel;
		this.debounceTime = args.debounceTime;
		this.value = args.value ?? [];
		this.secondaryAction = args.secondaryAction;
		this.secondaryActionLabel = args.secondaryActionLabel;
		this.headerDefinitions =
			args.headerDefinitions ??
			this.columns
				.filter((question) => question['type'] !== 'hidden')
				.map((question) => {
					return { label: question.label, key: question.key, helpText: question.helpText, class: question.class };
				});
		this.questions = deepClone(this.columns);
		this.questions.forEach((question) => {
			// need to remove if existing because we don't want to show the label and helpText for each row
			delete question.label;
			delete question.helpText;
		});
		this.rows = new Array<FormConfiguration>();
		const rows = this.getRowsByValue(this.value);
		if (rows.length > 0) {
			this.rows = rows;
		} else {
			this.addNewRow();
		}
	}

	getRowsByValue(value: Array<any>): FormConfiguration[] {
		const rows = new Array<FormConfiguration>();
		value.forEach((item) => {
			const row = new FormConfiguration();
			const questions = deepClone(this.questions);
			questions.forEach((question) => {
				question.value = getValueByKeyPath(item, question.key);
			});
			row.formControl.questions = questions;
			rows.push(row);
		});
		return rows;
	}

	addNewRow() {
		const formConfiguration = new FormConfiguration();
		formConfiguration.formControl.questions = deepClone(this.questions);
		this.rows.push(formConfiguration);
	}

	updateRowsByValue(value: Array<any>) {
		this.value = value;
		this.rows = this.getRowsByValue(deepClone(value) ?? []);
		if (this.rows.length === 0) {
			this.addNewRow();
		}
	}
}

export class DynamicInputFormItems extends InputBase<any> {
	private readonly questions: InputBase<any>[];
	rows: FormConfiguration[];
	items: InputBase<any>[];
	debounceTime: number;
	type: 'formItems' | 'hidden';
	addItemLabel: string;
	value: Array<any>;
	cardTitle: string;
	itemTitle: string;
	showAsCard: boolean;
	showDeleteButton: boolean;

	constructor(args: Partial<DynamicInputFormItems>) {
		super(args);
		this.controlType = 'formItems';
		this.type = 'formItems';
		this.itemTitle = args.itemTitle;
		this.items = args.items ?? [];
		this.addItemLabel = args.addItemLabel;
		this.showDeleteButton = args.showDeleteButton ?? false;
		this.debounceTime = args.debounceTime;
		this.cardTitle = args.cardTitle;
		this.value = args.value ?? [];
		this.showAsCard = args.showAsCard ?? false;
		this.secondaryAction = args.secondaryAction;
		this.secondaryActionLabel = args.secondaryActionLabel;
		this.questions = deepClone(this.items);
		this.rows = new Array<FormConfiguration>();
		const rows = this.getRowsByValue(this.value);
		if (rows.length > 0) {
			this.rows = rows;
		} else {
			this.addNewRow();
		}
	}

	getRowsByValue(value: Array<any>): FormConfiguration[] {
		const rows = new Array<FormConfiguration>();
		value.forEach((item) => {
			const row = new FormConfiguration();
			const questions = deepClone(this.questions);
			questions.forEach((question) => {
				question.value = getValueByKeyPath(item, question.key);

				if (question instanceof DynamicInputFormItems) {
					const nestedValue = getValueByKeyPath(item, question.key);
					question.updateRowsByValue(nestedValue ?? []);
				}
			});
			row.formControl.questions = questions;
			rows.push(row);
		});
		return rows;
	}

	addNewRow() {
		const formConfiguration = new FormConfiguration();
		formConfiguration.formControl.questions = deepClone(this.questions);
		this.rows.push(formConfiguration);
	}

	updateRowsByValue(value: Array<any>) {
		this.value = value;
		this.rows = this.getRowsByValue(deepClone(value) ?? []);
		if (this.rows.length === 0) {
			this.addNewRow();
		}
	}
}

export class PercentageInputField extends InputBase<any> {
	readonly type: HtmlInputType;
	min?: any;
	max?: any;
	numberOfDecimals: number;

	constructor(args: Partial<PercentageInputField>) {
		super(args);
		this.type = 'percentage';
		this.controlType = 'percentage';
		this.suffix = args.suffix ?? '%';
		this.min = args.min;
		this.max = args.max;
		this.numberOfDecimals = args.numberOfDecimals ?? 2;
		this.value = args.value;
	}
}

export function showOrHideField(
	formGroup: FormGroup,
	formConfiguration: FormConfiguration,
	key: string,
	type: HtmlInputType,
	show: boolean,
	updateFormControl = true
): Observable<InputField> {
	const field = <InputField>formConfiguration.formControl.questions.find((q) => q.key === key);
	const formControl = <FormControl>formGroup.controls[key];
	if (show) {
		field.type = type;
		if (type !== 'table') {
			field.disabled = false;
		}
	} else {
		field.type = 'hidden';
		field.disabled = true;
	}

	if (updateFormControl) {
		show ? formControl.enable() : formControl.disable();
	}
	return of(field);
}

export class DashedInputField extends InputBase<any> {
	dashesConfig: number[];
	type: HtmlInputType;
	constructor(args: Partial<DashedInputField>) {
		super(args);
		this.controlType = 'dashed';
		this.type = args.type;
		this.dashesConfig = args.dashesConfig;
	}
}
