import { DynamicFormCard, InputAsyncSearch, InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { LoanApplicationDto, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { FormGroup, Validators } from '@angular/forms';
import { FormConfiguration } from '../models/dynamic-form.model';
import { orderBy } from '@oper-client/shared/util-array';
import { COMMON_REGEX_EXPRESSIONS } from '../services/validator-constants';
import { ValidatorService } from '../services/validator.service';
import { CUSTOMER_INSIGHTS_CONFIG, debounceTimes } from '@oper-client/shared/configuration';
import { inject } from '@angular/core';
import { GoogleGeocodeService } from '@oper-client/shared/util-google-maps';
import { filter, map, Observable } from 'rxjs';

export default function (formData?: Partial<LoanApplicationDto>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const extraCollateralForm = new FormConfiguration();
	extraCollateralForm.setName('extraCollateralForm');
	extraCollateralForm.formControl.questions = [
		new InputField({
			key: 'id',
			value: formData?.extraCollateral?.id,
			type: 'hidden',
			required: false,
		}),
		new InputField({
			key: 'collateralAmount',
			label: 'ç.question.valueOfTheProperty.label',
			value: formData?.extraCollateral?.collateralAmount,
			type: 'text',
			readonly: true,
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.typeOfProperty.label',
			value: formData?.extraCollateral?.realtyType?.id,
			required: true,
			hideRequiredAsterisk: true,
			appendTo: null,
			validators: [],
			options: orderBy(resources?.[ResourceType.REALTY_TYPE] ?? [], 'order'),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputAsyncSearch({
			key: 'addressSearch',
			label: 'ç.feature.addressAutocomplete.searchForYourAdress',
			placeholder: 'ç.feature.addressAutocomplete.searchForYourAdress',
			bindValue: 'id',
			bindLabel: 'label',
			required: false,
			noFoundTextLabel: 'ç.misc.nothingFound',
			debounceTime: debounceTimes.m,
			clearAfterSearch: true,
			characterThreshold: 2,
			iconName: 'faMagnifyingGlass',
			prefillDefaultValue: false,
			hideArrow: true,
			class: 'span12',
			endpointSettings: {
				method: (searchTerm: string) => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					const customerInsight = inject(CUSTOMER_INSIGHTS_CONFIG);
					const countryRestriction = customerInsight?.addressAutocompleteRestrictions?.realty ?? [];
					return googleGeocodeService.searchAddresses(searchTerm, countryRestriction);
				},
				transform: (addresses: any[]) => {
					return addresses.map((address) => ({
						id: address.place_id,
						label: address.description,
					}));
				},
				transformSelectedValue: (value: any): any => {
					const googleGeocodeService = inject(GoogleGeocodeService);
					return googleGeocodeService.getAddressDetails(value, value.id, null, resources[ResourceType.COUNTRY]);
				},
			},
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputAsyncSearch> => {
				const key = 'addressSearch';
				const inputField = <InputAsyncSearch>formConfiguration.formControl.questions.find((q) => q.key === key);

				return formGroup.get(key)?.valueChanges.pipe(
					filter((placeId) => !!placeId),
					filter((addressDetails) => !!addressDetails && !!addressDetails.address),
					map((addressDetails) => {
						addressDetails = { ...addressDetails, ...addressDetails.address };
						if (addressDetails.country) {
							formGroup.patchValue({
								'address.street': addressDetails.street || null,
								'address.houseNumber': addressDetails.houseNumber || null,
								'address.city': addressDetails.city || null,
								'address.zipCode': addressDetails.zipCode || null,
								'address.country.id': addressDetails.country.id || null,
							});
							formGroup.markAsDirty();
						}
						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.extraCollateral?.address?.street || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
			order: 1,
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.extraCollateral?.address?.houseNumber || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span4',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.extraCollateral?.address?.zipCode || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span4',
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.extraCollateral?.address?.city || '',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
			class: 'span8',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.extraCollateral?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcBeforeRenovations',
			label: 'ç.question.epcScore.label',
			value: formData?.extraCollateral?.epcBeforeRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			hideRequiredAsterisk: true,
			suffix: 'ç.misc.kWhM2',
			transform: (value) => +value,
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcReferenceNumber.label',
			value: formData?.extraCollateral?.epcContractNumber,
			updateOn: 'change',
			type: 'text',
			required: true,
			hideRequiredAsterisk: true,
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.extraCollateral?.epcDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
		}),
	];
	const collateralForm = new FormConfiguration();
	collateralForm.setName('collateralForm');
	collateralForm.formControl.questions = [
		new InputField({
			key: 'id',
			value: formData?.extraCollateral?.id,
			type: 'hidden',
			required: false,
		}),
		new InputField({
			key: 'collateralAmount',
			label: 'ç.question.valueOfTheProperty.label',
			value: formData?.extraCollateral?.collateralAmount,
			type: 'text',
			readonly: true,
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
	];

	const questions: InputBase<any>[] = [
		new InputField({
			key: 'ownFunds',
			label: 'ç.question.ownFunds.label',
			value: formData?.ownFunds || null,
			updateOn: 'change',
			type: 'text',
			readonly: true,
			currency: true,
			required: true,
			hideRequiredAsterisk: true,
		}),
	];

	const hasCollateral = !!formData?.collateralRealty?.id || !!formData?.extraCollateral?.id;
	if (hasCollateral) {
		if (
			formData?.collateralRealty?.purposes?.length === 1 &&
			formData?.collateralRealty?.purposes?.find((x) => x.definition === 'collateral')
		) {
			questions.push(
				new DynamicFormCard({
					title: 'ç.misc.additionalCollateral',
					key: 'extraCollateral',
					value: formData?.extraCollateral,
					formConfiguration: extraCollateralForm,
					showDeleteButton: !formData?.extraCollateral?.id,
					secondaryActionLabel: 'ç.misc.action.addCollateral',
					secondaryAction: true,
					forceExpand: true,
					required: false,
				})
			);
		} else {
			questions.push(
				new DynamicFormCard({
					title: 'ç.misc.additionalCollateral',
					key: 'extraCollateral',
					value: formData?.extraCollateral,
					formConfiguration: collateralForm,
					showDeleteButton: !formData?.extraCollateral?.id,
					secondaryActionLabel: 'ç.misc.action.addCollateral',
					secondaryAction: true,
					forceExpand: true,
					required: false,
				})
			);
		}
	}
	return questions;
}
