import { createReducer, on } from '@ngrx/store';
import * as ProductExplorerActions from './product-explorer.actions';

import {
	findUniqueProductIndex,
	Offer,
	Product,
	ProductExplorerSolution,
	ProductExplorerStep,
	ProductSearchFilterParams,
	ProductSortableKeys,
	UniqueProduct,
} from '@oper-client/shared/data-model';
import { getNextSortOrderState, SortDirection, SortOrder } from '@oper-client/shared/util-array';

export const PRODUCT_EXPLORER_ENTITY_KEY = 'productExplorer';

const DEFAULT_PRODUCTS_SORT_ORDER: SortOrder<ProductSortableKeys> = {
	sortBy: 'rate',
	sortDirection: SortDirection.ASC,
};

export interface State {
	activeStep: ProductExplorerStep;
	offerToUpdate: Partial<Offer>;
	selectedProducts: Product[] | UniqueProduct[];
	searchFilterParams: ProductSearchFilterParams;
	productsSortOrder: SortOrder<ProductSortableKeys>;
	solutions: ProductExplorerSolution[];
	activeSolutionIndex: number;
}

const searchFilterParamsDefaultState = {
	creditProvider: null,
	productType: null,
	loanType: null,
	minDuration: null,
	maxDuration: null,
	variabilityGroup: null,
	fixedVariability: null,
};

export const initialState: State = {
	activeStep: null,
	offerToUpdate: null,
	selectedProducts: [],
	productsSortOrder: DEFAULT_PRODUCTS_SORT_ORDER,
	searchFilterParams: searchFilterParamsDefaultState,
	solutions: [
		{
			selectedProducts: [],
		},
	],
	activeSolutionIndex: 0,
};

export const reducer = createReducer(
	initialState,

	on(ProductExplorerActions.setActiveStep, (state, { activeStep }) => ({
		...state,
		activeStep,
	})),

	on(ProductExplorerActions.setOfferToUpdate, (state, { offerToUpdate }) => ({
		...state,
		offerToUpdate,
	})),

	on(ProductExplorerActions.setSelectedProducts, (state, { selectedProducts }) => ({
		...state,
		selectedProducts,
	})),

	on(ProductExplorerActions.sortProductsByKey, (state, { sortableKey }) => ({
		...state,
		productsSortOrder: getNextSortOrderState(sortableKey, state.productsSortOrder),
	})),

	on(ProductExplorerActions.setSearchFilterParams, (state, { searchFilterParams }) => ({
		...state,
		searchFilterParams: {
			...state.searchFilterParams,
			...searchFilterParams,
		},
	})),

	on(ProductExplorerActions.reset, () => ({
		...initialState,
	})),

	on(ProductExplorerActions.selectProducts, (state, { products }) => ({
		...state,
		selectedProducts: [...state.selectedProducts, ...products],
	})),

	on(ProductExplorerActions.unselectProducts, (state, { products }) => ({
		...state,
		selectedProducts: state.selectedProducts.filter(
			(selectedProduct) => !products.some((_, index, array) => index === findUniqueProductIndex(array, selectedProduct))
		),
	})),

	on(ProductExplorerActions.clearProductSearchFilters, (state) => ({
		...state,
		searchFilterParams: searchFilterParamsDefaultState,
	})),

	on(ProductExplorerActions.createSolution, (state) => ({
		...state,
		solutions: [
			...state.solutions,
			{
				selectedProducts: [],
			},
		],
		activeSolutionIndex: state.solutions.length,
	})),

	on(ProductExplorerActions.removeSolution, (state, { index }) => {
		const newSolutions = state.solutions.filter((_, i) => i !== index);

		let newActive = state.activeSolutionIndex;
		if (index < newActive) newActive--;
		if (newActive >= newSolutions.length) newActive = newSolutions.length - 1;
		newActive = Math.max(newActive, 0);

		return {
			...state,
			solutions: newSolutions,
			activeSolutionIndex: newActive,
		};
	}),

	on(ProductExplorerActions.setActiveSolution, (state, { index }) => ({
		...state,
		activeSolutionIndex: index,
	})),

	on(ProductExplorerActions.updateSolutionProducts, (state, { index, products }) => {
		const newSolutions = [...state.solutions];
		newSolutions[index] = { ...newSolutions[index], selectedProducts: products };
		return { ...state, solutions: newSolutions };
	})
);
