import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DATE_RESTRICTION } from '../services/validator-constants';
import * as moment from 'moment';
import { Resource } from '@oper-client/shared/data-model';
import { distinctUntilChanged } from 'rxjs';
import { FormConfiguration } from '../models/dynamic-form.model';

export function dateRestrictionValidatior(
	validationMessageKey: string,
	firstOffset: number,
	dateRestriction: DATE_RESTRICTION,
	secondOffset?: number,
	transform?: (firstOffset, secondOffset) => any
): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (moment.isMoment(control.value)) {
			return null;
		}
		const date: Date = new Date(control.value);
		if (!(date && date.getTime())) {
			return null;
		}
		const dateMS: number = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();

		const returnValue = {
			[validationMessageKey]: transform ? transform(firstOffset, secondOffset) : firstOffset,
		};
		switch (dateRestriction) {
			case DATE_RESTRICTION.PAST:
				if (dateMS < firstOffset) {
					return returnValue;
				}
				break;
			case DATE_RESTRICTION.FUTURE:
				if (dateMS > firstOffset) {
					return returnValue;
				}
				break;
			case DATE_RESTRICTION.BOTH:
				if (dateMS < firstOffset || dateMS > secondOffset) {
					return returnValue;
				}
				break;
			default:
				return null;
		}
	};
}

export function endDateRequiredValidator(
	contractTypeIdKey: string,
	conditionalValues: string[],
	formConfig: FormConfiguration,
	resources: any
): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const conditionControl: AbstractControl = control?.parent?.controls[contractTypeIdKey];

		if (conditionControl) {
			const conditionControlValue = resources.find((resource: Resource) => resource.id === conditionControl.value)?.definition;
			if (conditionalValues.includes(conditionControlValue) && !control.value) {
				return { required: true };
			}
			conditionControl?.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
				return control.updateValueAndValidity();
			});
			return null;
		}
		return null;
	};
}
