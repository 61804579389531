import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject, Observable, throwError, of } from 'rxjs';
import { catchError, map, skip, switchMap } from 'rxjs/operators';

import { API_SERVICE, convertObjectToHttpParams, IApiService, IAuthService } from '@oper-client/shared/data-access';
import { LocalStorageService } from '@oper-client/shared/util-client-storage';
import { IAM, JoinDetails } from '@oper-client/shared/data-model';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { EnvironmentLocaleFormatService } from '@oper-client/shared/util-formatting';
import { APP_INSIGHTS_CONFIG, ApplicationInsights, ApplicationName } from '@oper-client/shared/configuration';

@Injectable()
export class AuthService implements IAuthService {
	public token$: Subject<any>;

	constructor(
		@Inject(API_SERVICE) private readonly apiService: IApiService,
		@Inject(APP_INSIGHTS_CONFIG) private readonly applicationInsights: ApplicationInsights,
		private readonly localStorageService: LocalStorageService,
		private readonly jwtHelper: JwtHelperService,
		private readonly localeFormatService: EnvironmentLocaleFormatService
	) {
		this.token$ = new BehaviorSubject<any>({ access: this.localStorageService.get('accessToken') });
		this.token$.pipe(skip(1)).subscribe((tokens) => {
			if (typeof tokens.access !== 'undefined') {
				if (tokens.access) {
					this.localStorageService.set('accessToken', tokens.access);
				} else {
					this.localStorageService.unset('accessToken');
				}
			}
			// For refreshes, we don't get a new refresh token.
			if (typeof tokens.refresh !== 'undefined') {
				if (tokens.refresh) {
					this.localStorageService.set('refreshToken', tokens.refresh);
				} else {
					this.localStorageService.unset('refreshToken');
				}
			}
		});
	}

	public setTokens(tokens) {
		this.token$.next(tokens);
	}

	public refreshToken(): Observable<any> {
		const token = this.getRefreshToken();
		if (!token) {
			return throwError('No refresh token');
		}
		const body = {
			refresh: token,
		};
		return this.apiService.post('/api/jwt/refresh/', body).pipe(
			map((response) => {
				this.token$.next(response);
			}),
			catchError((error) => {
				return throwError(error);
			})
			// mergeMap(() => {
			// 	return this.permissionsService.setPermissions();
			// })
		);
	}

	public getAccessToken() {
		return this.localStorageService.get('accessToken');
	}

	public getRefreshToken() {
		return this.localStorageService.get('refreshToken');
	}

	public unsetTokens() {
		this.token$.next({ access: null, refresh: null });
	}

	public isAuthenticated() {
		return !this.jwtHelper.isTokenExpired();
	}

	public login(body: IAM.UserCredentials): Observable<IAM.JwtTokens> {
		return this.apiService.post('/api/jwt/', body);
	}

	public getCurrentUser(): Observable<IAM.User> {
		return this.token$.pipe(
			map((tokens) => tokens?.access && (this.jwtHelper.decodeToken(tokens.access) as IAM.UnpackedJWT)),
			switchMap((unpackedJWT) => {
				if (unpackedJWT) {
					const { role, language } = unpackedJWT;

					return this.apiService.get('/api/me/').pipe(
						map(
							(apiUser) =>
								({
									...apiUser,
									role: role.definition,
									language: apiUser.language ? apiUser.language.definition : language.definition,
									fullname: `${this.localeFormatService.formatFullName(apiUser)}`,
								}) as IAM.User
						)
					);
				} else {
					return of(null);
				}
			})
		);
	}

	updateCurrentUser(user: Partial<IAM.User>): Observable<IAM.User> {
		return this.apiService.patch(`/api/me/`, { bank_ids: user.bankIds });
	}

	public logout(): Observable<void> {
		this.unsetTokens();
		return new Observable((observer) => {
			observer.next();
			observer.complete();
		});
	}

	public forgotPassword(email: string): Observable<any> {
		const body = {
			email: email,
		};
		const applicationName = this.applicationInsights.appName;
		const params = convertObjectToHttpParams({
			'user-type':
				applicationName === ApplicationName.BROKERAGE
					? 'application-user'
					: applicationName === ApplicationName.SELF_SERVICE
						? 'client-user'
						: 'base-user',
		});
		return this.apiService.post('/api/forgot-password/', body, params);
	}

	public resetPassword(body: IAM.ResetPassword): Observable<any> {
		return this.apiService.post('/api/reset-password/', body);
	}

	public resetPassword2Fa(body: IAM.ResetPassword): Observable<any> {
		return this.apiService.post('/api/sign-in/reset-password/', body);
	}

	public checkResetPasswordToken(token: string) {
		const body = {
			token: token,
		};
		return this.apiService.post('/api/check-reset-password/', body);
	}

	public activateUser(token: string): Observable<void> {
		return this.apiService.post('/api/me/activate/', { token });
	}

	public verifyUser(code: string, token: string): Observable<void> {
		return this.apiService.post('/api/me/verify/', { token, code });
	}

	public validateCredentials(username: string, password: string): Observable<any> {
		return this.apiService.post('/api/sign-in/otp/', { username, password });
	}

	public activateOTP(token: string): Observable<any> {
		return this.apiService.post('/api/sign-in/activate/', { token });
	}

	public verifyOTP(token: string, code: string): Observable<any> {
		return this.apiService.post('/api/sign-in/verify/', { code, token });
	}

	public getPrivacyPolicy(
		token: string,
		language: string,
		alternativeLanguage: string,
		params: HttpParams = new HttpParams()
	): Observable<any> {
		return this.apiService.get(
			`/api/privacy-policy/`,
			params.set('token', token).set('language', language).set('alternative_language', alternativeLanguage)
		);
	}

	getTermsAndConditions(
		token: string,
		language: string,
		alternativeLanguage: string,
		params: HttpParams = new HttpParams()
	): Observable<any> {
		return this.apiService.get(
			`/api/terms-and-conditions/`,
			params.set('token', token).set('language', language).set('alternative_language', alternativeLanguage)
		);
	}

	public createUser(userData: JoinDetails): Observable<any> {
		return this.apiService.post('/api/sign-up/create/', userData);
	}

	public updateProfile(profileData: JoinDetails): Observable<{ token: string }> {
		return this.apiService.put('/api/sign-up/profile/', profileData);
	}

	public inviteUser(userData: JoinDetails): Observable<any> {
		return this.apiService.post('/api/sign-up/reset-password/', userData);
	}

	public checkInviteToken(token: string) {
		const body = {
			token: token,
		};
		return this.apiService.post('/api/check-invite-token/', body);
	}
}
