import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { Offer, Product, ProductExplorerStep, ProductSearchFilterParams, ProductSortableKeys } from '@oper-client/shared/data-model';

import * as ProductExplorerActions from './product-explorer.actions';
import { reset, setActiveStep, setOfferToUpdate, setSearchFilterParams, setSelectedProducts } from './product-explorer.actions';
import * as ProductExplorerSelectors from './product-explorer.selectors';
import { getActiveStep, getOfferToUpdate, getSearchFilterParams, getSelectedProducts } from './product-explorer.selectors';
import { LoanRequestPartialState } from '../loan-request.reducer';
import { combineLatest, Observable, take } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { getSortedList } from '@oper-client/shared/util-array';
import { getLoadedProductsProgress, getProducts } from '../product/product.selectors';

@Injectable()
export class ProductExplorerFacade {
	activeStep$ = this.store.pipe(select(getActiveStep));
	offerToUpdate$: Observable<Partial<Offer>> = this.store.pipe(select(getOfferToUpdate));
	products$ = this.store.pipe(select(getProducts));
	selectedProducts$: Observable<Product[]> = this.store.pipe(select(getSelectedProducts));
	searchFilterParams$ = this.store.pipe(select(getSearchFilterParams));
	totalLoadedProductsProgress$ = this.store.pipe(select(getLoadedProductsProgress));

	readonly productsSortOrder$ = this.store.pipe(select(ProductExplorerSelectors.getProductsSortOrder));
	readonly sortedProducts$: Observable<Product[]> = combineLatest([this.products$, this.productsSortOrder$]).pipe(
		map(([products, sortOrder]) => getSortedList(products, sortOrder)),
		shareReplay(1)
	);

	// Solutions
	readonly solutions$ = this.store.pipe(select(ProductExplorerSelectors.getSolutions));
	readonly activeSolutionIndex$ = this.store.pipe(select(ProductExplorerSelectors.getActiveSolutionIndex));
	readonly activeSolutionProducts$ = this.store.pipe(select(ProductExplorerSelectors.getActiveSolutionProducts));
	readonly allSolutionProducts$ = this.store.pipe(select(ProductExplorerSelectors.getAllSolutionProducts));

	constructor(private store: Store<LoanRequestPartialState>) {}

	setActiveStep(activeStep: ProductExplorerStep): void {
		this.store.dispatch(setActiveStep({ activeStep }));
	}

	sortProductsByKey(sortableKey: ProductSortableKeys) {
		this.store.dispatch(ProductExplorerActions.sortProductsByKey({ sortableKey }));
	}

	setOfferToUpdate(offerToUpdate: Partial<Offer>): void {
		this.store.dispatch(setOfferToUpdate({ offerToUpdate }));
	}

	setSelectedProducts(selectedProducts: Product[]): void {
		const products = selectedProducts.map((selectedProduct) => Object.assign({}, selectedProduct));
		this.store.dispatch(setSelectedProducts({ selectedProducts: products }));
		this.updateActiveSolutionProducts(products);
	}

	setSearchFilterParams(searchFilterParams: ProductSearchFilterParams): void {
		this.store.dispatch(setSearchFilterParams({ searchFilterParams }));
	}

	clearProductSearchFilters() {
		this.store.dispatch(ProductExplorerActions.clearProductSearchFilters());
	}

	reset(): void {
		this.store.dispatch(reset());
	}

	createSolution() {
		this.store.dispatch(ProductExplorerActions.createSolution());
	}

	removeSolution(index: number) {
		this.store.dispatch(ProductExplorerActions.removeSolution({ index }));
	}

	setActiveSolution(index: number) {
		this.store.dispatch(ProductExplorerActions.setActiveSolution({ index }));
	}

	updateSolutionProducts(index: number, products: Product[]) {
		this.store.dispatch(ProductExplorerActions.updateSolutionProducts({ index, products }));
	}

	updateActiveSolutionProducts(products: Product[]) {
		combineLatest([this.activeSolutionIndex$, this.solutions$])
			.pipe(take(1))
			.subscribe(([activeIndex, solutions]) => {
				if (activeIndex < 0 || activeIndex >= solutions.length) return;
				this.updateSolutionProducts(activeIndex, products);
			});
	}
}
