import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, Observable, take } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import {
	parseActionState,
	ParsedActionState,
	Product,
	ProductExplorerSolution,
	ProductSearchFilterParams,
	ProductSortableKeys,
	SelectedBaseProductDiscounts,
	Simulator,
	UniqueProduct,
} from '@oper-client/shared/data-model';
import * as AdvisorSimulatorActions from './advisor-simulator.actions';
import * as AdvisorSimulatorSelectors from './advisor-simulator.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { getSortedList, SortOrder } from '@oper-client/shared/util-array';
import { GtmEventNameEnum, GtmFacade } from '@oper-client/shared/tracking/gtm';
import { SimulatorActionTypes } from './advisor-simulator.reducer';

@Injectable()
export class AdvisorSimulatorFacade {
	constructor(
		private store: Store<any>,
		private actions$: Actions,
		private readonly gtmFacade: GtmFacade
	) {}

	readonly calculateSimulationActionState$ = this.selectActionState('calculateSimulation');
	readonly loadSimulationsActionState$ = this.selectActionState('loadSimulations');
	readonly loadMoreSimulationsActionState$ = this.selectActionState('loadMoreSimulations');
	readonly loadSimulationActionState$ = this.selectActionState('loadSimulation');
	readonly updateSimulationActionState$ = this.selectActionState('updateSimulation');
	readonly removeSimulationActionState$ = this.selectActionState('removeSimulation');
	readonly createSimulationActionState$ = this.selectActionState('createSimulation');
	readonly loadProductsActionState$ = this.selectActionState('loadProducts');
	readonly loadOffersActionState$ = this.selectActionState('loadOffers');
	readonly updateOfferActionState$ = this.selectActionState('updateOffer');
	readonly loadDiscountsActionState$ = this.selectActionState('loadDiscounts');
	readonly loadConfigurationActionState$ = this.selectActionState('loadConfiguration');
	readonly createLoanRequestFromSimulationActionState$ = this.selectActionState('convertSimulationToLoanRequest');
	readonly loadBorrowerDocumentTypesActionState$ = this.selectActionState('loadBorrowerDocumentTypes');

	readonly borrowerDocumentTypes$ = this.store.pipe(select(AdvisorSimulatorSelectors.getBorrowerDocumentTypes));
	readonly convertToLoanRequestResponse$ = this.store.pipe(select(AdvisorSimulatorSelectors.getConvertToLoanRequestResponse));
	readonly simulations$ = this.store.pipe(select(AdvisorSimulatorSelectors.getSimulations));
	readonly simulationsSortOrder$ = this.store.pipe(select(AdvisorSimulatorSelectors.getSimulationsSortOrder));
	readonly activeSimulatorStep$ = this.store.pipe(select(AdvisorSimulatorSelectors.getActiveSimulatorStep));
	readonly simulatorInformationBox$ = this.store.pipe(select(AdvisorSimulatorSelectors.getSimulatorInformationBox));
	readonly productsSortOrder$ = this.store.pipe(select(AdvisorSimulatorSelectors.getProductsSortOrder));
	readonly products$ = this.store.pipe(select(AdvisorSimulatorSelectors.getProducts));
	readonly offers$ = this.store.pipe(select(AdvisorSimulatorSelectors.getOffers));
	readonly selectedOfferIds$ = this.store.pipe(select(AdvisorSimulatorSelectors.getSelectedOfferIds));
	readonly discounts$ = this.store.pipe(select(AdvisorSimulatorSelectors.getDiscounts));
	readonly removeOfferAction$ = this.actions$.pipe(ofType(AdvisorSimulatorActions.removeOffer));
	readonly removeOffersAction$ = this.actions$.pipe(ofType(AdvisorSimulatorActions.removeOffers));
	readonly productSearchFilters$ = this.store.pipe(select(AdvisorSimulatorSelectors.getProductSearchFilters));
	readonly searchSimulationResponse$ = this.store.pipe(select(AdvisorSimulatorSelectors.getSearchSimulationResponse));
	readonly isAssignAnalystDialogVisible$ = this.store.pipe(select(AdvisorSimulatorSelectors.getAssignAnalystDialogVisibility));
	readonly viewMode$ = this.store.pipe(select(AdvisorSimulatorSelectors.getViewMode));
	readonly isReadonlyMode$ = this.viewMode$.pipe(map((viewMode) => viewMode !== Simulator.ViewMode.EDITABLE));
	readonly totalLoadedProductsProgress$ = this.store.pipe(select(AdvisorSimulatorSelectors.getLoadedProductsProgress));

	// Solutions
	readonly solutions$ = this.store.pipe(select(AdvisorSimulatorSelectors.getSolutions));
	readonly activeSolutionIndex$ = this.store.pipe(select(AdvisorSimulatorSelectors.getActiveSolutionIndex));
	readonly activeSolutionProducts$ = this.store.pipe(select(AdvisorSimulatorSelectors.getActiveSolutionProducts));
	readonly allSolutionsProducts$ = this.store.pipe(select(AdvisorSimulatorSelectors.getAllSolutionProducts));

	readonly configuration$ = this.store.pipe(select(AdvisorSimulatorSelectors.getConfiguration)).pipe(
		filter((configuration) => !!configuration),
		shareReplay(1)
	);

	readonly currentSimulation$ = this.store.pipe(select(AdvisorSimulatorSelectors.getCurrentSimulation)).pipe(
		filter((simulation) => !!simulation),
		shareReplay(1)
	);

	readonly sortedProducts$: Observable<Product[]> = combineLatest([this.products$, this.productsSortOrder$]).pipe(
		map(([products, sortOrder]) => getSortedList(products, sortOrder)),
		shareReplay(1)
	);

	readonly selectedDiscounts$ = this.currentSimulation$.pipe(
		map((simulation) => simulation?.selectedDiscounts ?? []),
		shareReplay(1)
	);

	readonly configuredSimulatorSteps$ = this.configuration$.pipe(
		map((configuration) => configuration?.steps ?? []),
		shareReplay(1)
	);

	readonly formDebounceTime$ = this.configuration$.pipe(map((configuration) => configuration.formDebounceTime));

	showInformationBox(step: Simulator.SimulatorStepEnum): Observable<boolean> {
		return this.store.pipe(
			select(AdvisorSimulatorSelectors.getSimulatorInformationBox),
			map((informationBox) => informationBox[step])
		);
	}

	getStepConfiguration(step: Simulator.SimulatorStepEnum) {
		return this.configuration$.pipe(map((configuration) => configuration[step]));
	}

	calculateSimulation(payload: Simulator.CalculateSimulationPayload): void {
		this.store.dispatch(AdvisorSimulatorActions.calculateSimulation({ payload }));
	}

	createSimulation(simulation?: Partial<Simulator.Simulation>): void {
		this.store.dispatch(AdvisorSimulatorActions.createSimulation({ simulation }));
	}

	updateSimulation(simulationId: number, simulation: Partial<Simulator.Simulation>): void {
		this.store.dispatch(AdvisorSimulatorActions.updateSimulation({ simulationId, simulation }));
	}

	removeSimulation(simulationResultId: number): void {
		this.store.dispatch(AdvisorSimulatorActions.removeSimulation({ simulationId: simulationResultId }));
	}

	loadSimulations(queryParams: Partial<Simulator.SearchSimulationQueryParams>, ordering: SortOrder<string>[] = []): void {
		this.store.dispatch(AdvisorSimulatorActions.loadSimulations({ queryParams, ordering }));
	}

	loadSimulation(simulationId: number): void {
		this.store.dispatch(AdvisorSimulatorActions.loadSimulation({ simulationId }));
	}

	setActiveSimulatorStep(step: Simulator.SimulatorStepEnum): void {
		this.store.dispatch(AdvisorSimulatorActions.setActiveSimulatorStep({ step }));
		if (step === Simulator.SimulatorStepEnum.COSTS_AND_FINANCING) {
			this.gtmFacade.sendEvent(GtmEventNameEnum.SIMULATOR_ADD_PROJECT_CONTINUE);
		} else if (step === Simulator.SimulatorStepEnum.BORROWER_DETAILS) {
			this.gtmFacade.sendEvent(GtmEventNameEnum.SIMULATOR_ASSESS_FINANCES_CONTINUE);
		} else if (step === Simulator.SimulatorStepEnum.EXPLORE_PRODUCTS) {
			this.gtmFacade.sendEvent(GtmEventNameEnum.SIMULATOR_SELECT_PRODUCTS_CONTINUE);
		}
	}

	setViewMode(viewMode: Simulator.ViewMode): void {
		this.store.dispatch(AdvisorSimulatorActions.setViewMode({ viewMode }));
	}

	setCurrentSimulation(simulation: Simulator.Simulation): void {
		this.store.dispatch(AdvisorSimulatorActions.setCurrentSimulation({ simulation }));
	}

	clearCurrentSimulation(): void {
		this.store.dispatch(AdvisorSimulatorActions.clearCurrentSimulation());
	}

	clearActiveSimulatorStep(): void {
		this.store.dispatch(AdvisorSimulatorActions.clearActiveSimulatorStep());
	}

	cleanUpSimulations() {
		this.store.dispatch(AdvisorSimulatorActions.cleanUpSimulations());
	}

	dispatch(action: Action): void {
		this.store.dispatch(action);
	}

	getActionStateSuccess(actionType: SimulatorActionTypes): Observable<boolean> {
		return this.selectActionState(actionType).pipe(map(({ success }) => !!success));
	}

	getActionStateFailure(actionType: SimulatorActionTypes): Observable<boolean> {
		return this.selectActionState(actionType).pipe(map(({ httpError }) => typeof httpError !== 'undefined'));
	}

	getActionStateProcessing(actionType: SimulatorActionTypes): Observable<boolean> {
		return this.selectActionState(actionType).pipe(map(({ processing }) => !!processing));
	}

	clearProductSearchFilters() {
		this.store.dispatch(AdvisorSimulatorActions.clearProductSearchFilters());
	}

	setProductSearchFilters(filters: ProductSearchFilterParams) {
		this.store.dispatch(AdvisorSimulatorActions.setProductSearchFilters({ filters }));
	}

	hideInformationBox(step: Simulator.SimulatorStepEnum) {
		this.store.dispatch(AdvisorSimulatorActions.hideInformationBox({ step }));
	}

	loadProducts(payload: Partial<Simulator.SearchProductsPayload>, filters: ProductSearchFilterParams) {
		this.store.dispatch(AdvisorSimulatorActions.loadProducts({ payload, filters }));
	}

	clearProducts() {
		this.store.dispatch(AdvisorSimulatorActions.clearProducts());
	}

	clearSolutions() {
		this.store.dispatch(AdvisorSimulatorActions.clearSolutions());
	}

	selectProducts(products: UniqueProduct[]) {
		this.store.dispatch(AdvisorSimulatorActions.selectProducts({ products }));
	}

	unselectProducts(products: UniqueProduct[]) {
		this.store.dispatch(AdvisorSimulatorActions.unselectProducts({ products }));
	}

	selectAllProducts() {
		this.store.dispatch(AdvisorSimulatorActions.selectAllProducts());
	}

	unselectAllProducts() {
		this.store.dispatch(AdvisorSimulatorActions.unselectAllProducts());
	}

	selectProduct(product: UniqueProduct) {
		this.store.dispatch(AdvisorSimulatorActions.selectProduct({ product }));
	}

	unselectProduct(product: UniqueProduct) {
		this.store.dispatch(AdvisorSimulatorActions.unselectProduct({ product }));
	}

	sortProductsByKey(sortableKey: ProductSortableKeys) {
		this.store.dispatch(AdvisorSimulatorActions.sortProductsByKey({ sortableKey }));
	}

	setSimulationsSortOrder(ordering: SortOrder<string>[]) {
		this.store.dispatch(AdvisorSimulatorActions.setSimulationsSortOrder({ ordering }));
	}

	loadOffers(payload: Partial<Simulator.LoadOffersPayload>, queryParams?: Partial<Simulator.LoadOffersQueryParams>) {
		this.store.dispatch(AdvisorSimulatorActions.loadOffers({ payload, queryParams }));
	}

	updateOffer(payload: Partial<Simulator.UpdateOfferPayload>, queryParams?: Partial<Simulator.UpdateOfferQueryParams>) {
		this.store.dispatch(AdvisorSimulatorActions.updateOffer({ payload, queryParams }));
	}

	clearOffers() {
		this.store.dispatch(AdvisorSimulatorActions.clearOffers());
	}

	removeOffer(offerId: number, creditProviderId: number) {
		this.store.dispatch(AdvisorSimulatorActions.removeOffer({ offerId, creditProviderId }));
	}

	removeOffers(offerIds: number[], creditProviderIds: number[]) {
		this.store.dispatch(AdvisorSimulatorActions.removeOffers({ offerIds, creditProviderIds }));
	}

	selectOffer(offerId: number) {
		this.store.dispatch(AdvisorSimulatorActions.selectOffer({ offerId }));
	}

	unselectOffer(offerId: number) {
		this.store.dispatch(AdvisorSimulatorActions.unselectOffer({ offerId }));
	}

	selectAllOffers() {
		this.store.dispatch(AdvisorSimulatorActions.selectAllOffers());
	}

	unselectAllOffers() {
		this.store.dispatch(AdvisorSimulatorActions.unselectAllOffers());
	}

	syncSelectedProducts(products: Product[]) {
		this.store.dispatch(AdvisorSimulatorActions.syncSelectedProducts({ products }));
	}

	clearActions() {
		this.store.dispatch(AdvisorSimulatorActions.clearActions());
	}

	loadDiscounts(payload: Partial<Simulator.LoadDiscountsPayload>, selectDefaultDiscounts = false) {
		this.store.dispatch(AdvisorSimulatorActions.loadDiscounts({ payload, selectDefaultDiscounts }));
	}

	clearDiscounts() {
		this.store.dispatch(AdvisorSimulatorActions.clearDiscounts());
	}

	setSelectedDiscounts(discounts: SelectedBaseProductDiscounts[]) {
		this.store.dispatch(AdvisorSimulatorActions.setSelectedDiscounts({ discounts }));
	}

	convertSimulationToLoanRequest(payload: Simulator.ConvertToLoanRequestPayload) {
		this.store.dispatch(AdvisorSimulatorActions.convertSimulationToLoanRequest({ payload }));
	}

	loadConfiguration(customer: string) {
		this.store.dispatch(AdvisorSimulatorActions.loadConfiguration({ customer }));
	}

	setConfiguration(configuration: Simulator.CustomerConfiguration) {
		this.store.dispatch(AdvisorSimulatorActions.setConfiguration({ configuration }));
	}

	loadBorrowerDocumentTypes() {
		this.store.dispatch(AdvisorSimulatorActions.loadBorrowerDocumentTypes());
	}

	loadMoreSimulations(searchResponse: Simulator.SearchSimulationResponse, ordering: SortOrder<string>[] = []) {
		this.store.dispatch(AdvisorSimulatorActions.loadMoreSimulations({ searchResponse, ordering }));
	}

	setAssignAnalystDialogVisibility(visibility: boolean) {
		this.store.dispatch(AdvisorSimulatorActions.setAssignAnalystDialogVisibility({ visibility }));
	}

	createSolution() {
		this.store.dispatch(AdvisorSimulatorActions.createSolution());
	}

	removeSolution(index: number) {
		this.store.dispatch(AdvisorSimulatorActions.removeSolution({ index }));
	}

	setActiveSolution(index: number) {
		this.store.dispatch(AdvisorSimulatorActions.setActiveSolution({ index }));
	}

	updateSolutionProducts(index: number, products: Product[]) {
		this.store.dispatch(AdvisorSimulatorActions.updateSolutionProducts({ index, products }));
	}

	updateActiveSolutionProducts(products: Product[]) {
		combineLatest([this.activeSolutionIndex$, this.solutions$])
			.pipe(take(1))
			.subscribe(([activeIndex, solutions]) => {
				if (activeIndex < 0 || activeIndex >= solutions.length) return;
				this.updateSolutionProducts(activeIndex, products);
			});
	}

	clearSimulations() {
		this.store.dispatch(AdvisorSimulatorActions.clearSimulations());
	}

	setSolutions(solutions: ProductExplorerSolution[]) {
		this.store.dispatch(AdvisorSimulatorActions.setSolutions({ solutions }));
	}

	private selectActionState(actionType: SimulatorActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(AdvisorSimulatorSelectors.getActionState(actionType)), map(parseActionState));
	}
}
