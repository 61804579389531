import { AnyObject } from './any-object.model';
import { Email } from './email.model';
import { CreditProvider, Offer, Product, SelectedBaseProductDiscounts } from './offer.model';
import { PhoneNumber } from './phone.model';
import { Resource } from './resource.model';
import { Broker } from './broker.model';
import { User } from './user.model';
import {
	ExternalCalculators,
	FinancialPlanLoanAmountItem,
	FinancialPlanOwnFundItem,
	MonthlyPaymentEstimation,
} from './financial-plan.model';
import { PaginatedSearchQueryParams } from './paginated-search-query-params.model';
import { PaginatedSearchResponse } from './paginated-search-response.model';
import { Realty } from './realty.model';
import { Liability } from './liability.model';
import { Income } from './income.model';
import { UniqueProduct } from './product-extended.model';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Simulator {
	export type SortableKeys = keyof Pick<Simulation, 'name' | 'created' | 'lastModified' | 'status' | 'source' | 'analyst'>;

	export enum SimulatorStepEnum {
		COSTS_AND_FINANCING = 'costs-and-financing',
		BORROWER_DETAILS = 'borrower-details',
		EXPLORE_PRODUCTS = 'explore-products',
		CREATE_SOLUTIONS = 'create-solutions',
		CREATE_REQUEST = 'create-request',
		PRINT = 'print',
	}

	export enum SimulatorStatusEnum {
		OPEN = 'open',
		ARCHIVED = 'archived',
		CONVERTED = 'converted',
		EXPIRED = 'expired',
	}

	export enum ViewMode {
		READONLY = 'readonly',
		ARCHIVED_READONLY = 'archived-readonly',
		EDITABLE = 'editable',
	}

	export enum SourceEnum {
		AGENT_PORTAL = 'agentPortal',
		BORROWER_PORTAL = 'borrowerPortal',
	}

	export enum RealtyFormKeysEnum {
		REALTY_EPC_BEFORE = 'epcBeforeRenovations',
		REALTY_EPC_AFTER = 'epcAfterRenovations',
	}

	export interface CostDetails {
		id?: number;
		amount: number;
		type: Resource;
	}

	export interface Metrics {
		surplus: number;
		dsti: number;
		ltv: number;
	}

	export interface Collateral
		extends Partial<Pick<Realty, 'address' | 'epcContractNumber' | 'epcBeforeRenovations' | 'epcDate' | 'livingPercentage'>> {
		id?: number;
		collateralAmount: number;
		originalLoanAmount?: number;
		creditProvider?: Partial<CreditProvider>;
		realtyType?: Resource;
		realtyUsageType?: Resource;
	}

	export interface Borrower {
		incomes: Income[];
		liabilities: Liability[];
		emails?: Email[];
		phoneNumbers?: PhoneNumber[];
		language?: Resource;
		firstName?: string;
		lastName?: string;
		birthDate?: string;
		numberOfDependents?: number;
		id?: number;
	}

	export interface BorrowerDetails {
		borrowersInfo: Borrower[];
		monthlyPaymentEstimations: MonthlyPaymentEstimation[];
		totalMonthlyAmount: number;
	}

	export interface RealtyDetails {
		realtyType: Resource;
		realtyUsageType: Resource;
		epcBeforeRenovations: number;
		epcAfterRenovations: number;
		purchaseSaleType?: Resource;
		landPurchaseType?: Resource;
		architectFees?: number;
	}

	export interface TotalCosts {
		totalCosts: number;
		totalFinancing: number;
		totalPurchasePrice: number;
		totalPurchaseCost: number;
		totalCreditCosts: number;
		totalRenovationCosts: number;
		totalOtherCosts: number;
		totalAdditionalCosts: number;
		totalAdditionalNeeds: number;
		totalFinancialAssets: number;
		totalRefinancingCosts: number;
		totalDistributionCosts: number;
	}

	export interface FinancialPlanDetails {
		/**
		 * The default financial plan that can be used to reset all changes made by the user
		 */
		financialPlan: AnyObject<number>;
		/**
		 * Keeps track for all the financial plan updates made by the user
		 */
		financialPlanOverriddenFields: AnyObject<number>;
		/**
		 * The financial plan which is calculated by system based on the user input
		 */
		calculatedFinancialPlan?: AnyObject<number>;
		financialPlanLoanAmountItems: FinancialPlanLoanAmountItem[];
		financialPlanOwnFundItems: FinancialPlanOwnFundItem[];
		externalCalculators: ExternalCalculators;
	}

	export interface FinancingDetails extends FinancialPlanDetails {
		loanPurposes: Resource[];
		realtyPrice: number;
		priceOfLand: number;
		buildingCosts: number;
		region: Resource;
		isMainFirstResidence: boolean;
		renovationCosts: CostDetails[];
		venalValueAfter: number;
		additionalNeeds: CostDetails[];
		refinancingAmount: number;
		buyOutAmount: number;
		venalValueBefore: number;
		fairMarketValue: number;
		freeUseMortgage: number;
		refinances?: Refinance[];
	}

	export interface BaseSimulation {
		id: number;
		created: Date;
		lastModified: Date;
		expirationDate?: Date;
	}

	export interface RefinanceTranche {
		originalLoanAmount: number;
		outstandingBalance: number;
		interestRate: number;
	}

	export interface Refinance {
		liabilityType?: Resource;
		tranches: Partial<RefinanceTranche>[];
		borrowerIndex: number;
		isCollateral: boolean;
		valueOfTheRealty?: number;
	}

	export interface MortgageRefinance extends Pick<Refinance, 'valueOfTheRealty' | 'liabilityType' | 'tranches'> {
		isCollateral: boolean;
	}

	export interface NonMortgageRefinance {
		outstandingBalance: number;
		liabilityType: Resource;
		interestRate: number;
	}

	export interface Simulation extends BaseSimulation, FinancingDetails, BorrowerDetails, RealtyDetails, Metrics, TotalCosts {
		name: string;
		selectedProducts: UniqueProduct[][];
		selectedDiscounts: SelectedBaseProductDiscounts[];
		isDefaultDiscountsOverridden: boolean;
		createdBy: User;
		analyst: User | null;
		broker: Broker | null;
		status: Resource;
		source?: Resource;
		loanRequest: number;
		collaterals?: Collateral[];
		defaultRefinanceLiabilityType?: Resource;
	}

	export interface CalculatedSimulationResult extends TotalCosts, FinancialPlanDetails {
		dsti: number;
		ltv: number;
		surplus: number;
		monthlyAmount: number;
	}

	type AdditionalPayloadFields =
		| 'loanPurposes'
		| 'realtyPrice'
		| 'priceOfLand'
		| 'venalValueAfter'
		| 'venalValueBefore'
		| 'fairMarketValue'
		| 'freeUseMortgage'
		| 'borrowersInfo'
		| 'region'
		| 'isMainFirstResidence'
		| 'financialPlan'
		| 'financialPlanOverriddenFields'
		| 'financialPlanLoanAmountItems'
		| 'financialPlanOwnFundItems'
		| 'externalCalculators'
		| 'refinancingAmount'
		| 'refinances'
		| 'buyOutAmount'
		| 'realtyUsageType'
		| 'purchaseSaleType'
		| 'epcBeforeRenovations'
		| 'epcAfterRenovations'
		| 'collaterals'
		| 'architectFees'
		| 'landPurchaseType'
		| 'realtyType';

	export interface SearchProductsPayload extends Pick<Simulation, AdditionalPayloadFields> {
		selectedDiscounts?: SelectedBaseProductDiscounts[];
	}

	export interface LoadOffersPayload extends Pick<Simulation, AdditionalPayloadFields> {
		selectedProducts: UniqueProduct[][];
	}

	export interface UpdateOfferPayload extends Pick<Simulation, AdditionalPayloadFields> {
		id: number;
		selectedProducts: Partial<Product>[];
	}

	export interface LoadDiscountsPayload {
		baseProductIds: number[];
		selectedDiscounts?: SelectedBaseProductDiscounts[];
	}

	export type CalculateSimulationPayload = Pick<
		Simulation,
		| 'loanPurposes'
		| 'realtyPrice'
		| 'priceOfLand'
		| 'region'
		| 'isMainFirstResidence'
		| 'venalValueAfter'
		| 'venalValueBefore'
		| 'fairMarketValue'
		| 'freeUseMortgage'
		| 'financialPlan'
		| 'financialPlanOverriddenFields'
		| 'financialPlanLoanAmountItems'
		| 'financialPlanOwnFundItems'
		| 'externalCalculators'
		| 'monthlyPaymentEstimations'
		| 'borrowersInfo'
		| 'refinancingAmount'
		| 'refinances'
		| 'buyOutAmount'
		| 'additionalNeeds'
		| 'renovationCosts'
		| 'buildingCosts'
		| 'totalMonthlyAmount'
		| 'realtyUsageType'
		| 'purchaseSaleType'
		| 'epcBeforeRenovations'
		| 'epcAfterRenovations'
		| 'collaterals'
		| 'realtyType'
		| 'architectFees'
		| 'landPurchaseType'
	>;

	export interface ConvertToLoanRequestPayload
		extends Pick<
			Simulation,
			| 'id'
			| 'analyst'
			| 'broker'
			| 'name'
			| 'loanPurposes'
			| 'region'
			| 'isMainFirstResidence'
			| 'priceOfLand'
			| 'realtyPrice'
			| 'buildingCosts'
			| 'renovationCosts'
			| 'venalValueAfter'
			| 'venalValueBefore'
			| 'fairMarketValue'
			| 'freeUseMortgage'
			| 'refinancingAmount'
			| 'refinances'
			| 'buyOutAmount'
			| 'additionalNeeds'
			| 'financialPlan'
			| 'financialPlanOverriddenFields'
			| 'financialPlanLoanAmountItems'
			| 'financialPlanOwnFundItems'
			| 'externalCalculators'
			| 'borrowersInfo'
			| 'status'
			| 'realtyType'
			| 'realtyUsageType'
			| 'purchaseSaleType'
			| 'epcBeforeRenovations'
			| 'epcAfterRenovations'
			| 'collaterals'
			| 'architectFees'
			| 'landPurchaseType'
		> {
		offers: Pick<Offer, 'items'>[];
		selectedDocumentTypes?: number[];
	}

	export interface ConvertSimulationToLoanRequestResponse {
		loanRequestId: number;
		mainBorrowerId: number;
		totalRequestsCreated: number;
		totalRequestsCreatedThisMonth: number;
	}

	export type CustomerStepConfiguration = {
		[key in SimulatorStepEnum]: any;
	};

	export interface CustomerSharedConfiguration {
		/**
		 * The steps that are available for the customer
		 * @example
		 * steps: [Simulator.SimulatorStepEnum.COSTS_AND_FINANCING, Simulator.SimulatorStepEnum.BORROWER_DETAILS, Simulator.SimulatorStepEnum.CREATE_REQUEST],
		 * **/
		steps: SimulatorStepEnum[];
		/**
		 * The configuration function for getting the default values for a new simulation per customer
		 * @param args The arguments passed to the function
		 * @returns The default values that are needed when creating a new simulation
		 * @example
		 * simulationPresets: (resources: NormalizedResource) => ({
		 *  loanPurposes: resources?.[ResourceType.LOAN_REQUEST_PURPOSE].filter((purpose) => purpose.definition === 'purchase'),
		 * realtyUsageType: resources?.[ResourceType.REALTY_USAGE_TYPE].find((type) => type.definition === 'living'),
		 * }),
		 **/
		simulationPresets: (...args) => Partial<Simulator.Simulation>;
		formDebounceTime: number;
	}

	export type CustomerConfiguration = CustomerSharedConfiguration & Partial<CustomerStepConfiguration>;

	export interface SimulationSearchResult
		extends Pick<
			Simulation,
			| 'id'
			| 'name'
			| 'created'
			| 'lastModified'
			| 'expirationDate'
			| 'status'
			| 'broker'
			| 'analyst'
			| 'source'
			| 'loanRequest'
			| 'loanPurposes'
		> {
		preapprovalStatus?: boolean;
		isReadonly?: boolean;
		loanAmount?: number;
	}

	export interface SearchSimulationQueryParams extends PaginatedSearchQueryParams {
		status: string | string[];
		source: string;
		analyst: number | null;
		obsolete: boolean;
	}

	export interface LoadOffersQueryParams {
		generatePricingSheetUrls: boolean;
		isBorrowerAcceptanceRules: boolean;
		simulationId: number | null;
		currentLanguage: string | null;
	}

	export interface UpdateOfferQueryParams {
		simulationId: number | null;
		isBorrowerAcceptanceRules: boolean;
	}

	export type SearchSimulationResponse = PaginatedSearchResponse<SimulationSearchResult>;
}
